import { MdModeEditOutline } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSort } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const SearchBar = ({ addVendorPath, editVendorPath, showEditButton, titleOne }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the search logic here
    console.log("Search triggered");
  };

  const navigate = useNavigate();

  const handleAddVendor = () => {
    navigate(addVendorPath);
  };

  const handleEditVendor = () => {
    navigate(editVendorPath);
  };

  return (
    <div className="block md:flex md:justify-between justify-center items-center py-4 pr-4">
      <form className="max-w-md w-full mb-6 " onSubmit={handleSubmit}>
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-[#045B9B]"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
            placeholder="Search"
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-[#045B9B] hover:bg-[#23567a] focus:outline-none font-medium rounded-lg text-sm px-4 py-2"
          >
            Search
          </button>
        </div>
      </form>
      <div className="flex mb-6">
        <button
          onClick={handleAddVendor}
          className="flex items-center justify-center px-4 py-2 text-white bg-[#045B9B] rounded hover:bg-[#23567a] mr-2 text-sm w-full md:w-auto"
        >
          <AiOutlinePlus className="mr-2" /> {titleOne}
        </button>
        {showEditButton && (
          <button
            onClick={handleEditVendor}
            className="flex items-center justify-center px-4 py-2 text-white bg-[#045B9B] rounded hover:bg-[#23567a] mr-2 text-sm w-full md:w-auto"
          >
            <MdModeEditOutline className="mr-2" /> Edit Vendor
          </button>
        )}

        <button className="flex items-center justify-center px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600 text-sm w-full md:w-auto">
          <BiSort className="mr-2" /> Sort by
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
