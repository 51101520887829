import Sidebar from "../../components/sidebar/Sidebar";
import React, { useState } from "react";
import { Form, Radio, Switch } from "antd";
import DataTable from "../../components/datatable/Datatable"; // import your reusable DataTable component
import { Space } from "antd"; // Space is from Ant Design
import { DownOutlined } from "@ant-design/icons"; // DownOutlined is from Ant Design Icons
import Main from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import MainSection from "../../components/mainLayout/Main";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import ToggleSwitch from "../../components/toggleSwitch/ToggleSwitch";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Age",
    dataIndex: "age",
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: "Address",
    dataIndex: "address",
    filters: [
      {
        text: "London",
        value: "London",
      },
      {
        text: "New York",
        value: "New York",
      },
    ],
    onFilter: (value, record) => record.address.indexOf(value) === 0,
  },
  {
    title: "Toggle",
    key: "toggle",
    render: (_, record) => <ToggleSwitch />,
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
    render: () => (
      <Space size="middle">
        <a>Delete</a>
        <a>
          <Space>
            More actions
            <DownOutlined />
          </Space>
        </a>
      </Space>
    ),
  },
];

const data = [];
for (let i = 1; i <= 10; i++) {
  data.push({
    key: i,
    name: "John Brown",
    age: Number(`${i}2`),
    address: `New York No. ${i} Lake Park`,
    description: `My name is John Brown, I am ${i}2 years old, living in New York No. ${i} Lake Park.`,
  });
}

const Dashboard = () => {
  const [bordered, setBordered] = useState(true);
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState("large");
  const [hasData, setHasData] = useState(true);
  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <SectionHeader title="Dashboard" />
      </MainSection>
    </>
  );
};

export default Dashboard;
