import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";

function AddVendor() {
  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Add Vendors" />

        <div className="container w-full md:w-1/2 lg:w-1/3  text-start">
          <div class="my-5">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Vendor Name
            </label>
            <input
              type="text"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Enter Vendor name"
              required
            />
          </div>

          <div class="mb-5">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Enter email address"
              required
            />
          </div>

          <div className="mb-5">
            <label
              for="countries"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Vendor Type
            </label>
            <select
              id="countries"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            >
              <option selected>Select Compliance or Registration</option>
              <option value="US">Select 1</option>
              <option value="CA">Select 2</option>
              <option value="FR">Select 3</option>
              <option value="DE">Select 4</option>
            </select>
          </div>

          <div class="mb-5">
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Phone Number
            </label>
            <input
              type="text"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Enter Phone number"
              required
            />
          </div>

          <div className="mb-5">
            <label
              for="countries"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Service
            </label>
            <select
              id="countries"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            >
              <option selected>Select Service</option>
              <option value="US">Select 1</option>
              <option value="CA">Select 2</option>
              <option value="FR">Select 3</option>
              <option value="DE">Select 4</option>
            </select>
          </div>

          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#045B9B] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create Vendor
          </button>
        </div>
      </MainSection>
    </div>
  );
}

export default AddVendor;
