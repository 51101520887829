import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import loginLogo from "../../assets/logo.png";
import ReusableButton from "../../components/button/Button";
import LoginBG from "../../assets/bg.png";

const Login = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/dashboard");
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="min-h-screen flex">
      {/* Left Section (Background and Login Form) */}
      <div
        className="bg-cover bg-center w-full lg:w-full min-h-screen flex items-center justify-center"
        style={{ backgroundImage: `url(${LoginBG})` }}
      >
        {/* Login Form */}
        <div className="flex-grow flex justify-center md:justify-start items-center lg:w-3/5 w-full sm:pl-16">
          <div className="w-11/12 max-w-md p-8 shadow-lg rounded-lg bg-white">
            <div className="login-logo mb-6">
              <img src={loginLogo} alt="Logo" className="mx-auto" />
            </div>

            <h3 className="text-2xl font-bold text-center text-orange-500 mb-6 leading-16 border-orange-500 border-b-4 pb-8">
              Admin Login
            </h3>

            <span className=""></span>

            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email ID"
                name="Email ID"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="Enter your Email ID" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password placeholder="Enter your password" />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
                className="text-left"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }} className="text-center">
                <ReusableButton
                  label="Login To Account"
                  className="login-btn bg-[#FE8114] text-white "
                  onClick={handleClick}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
