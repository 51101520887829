import "./Button.css"


const ReusableButton = ({ label, className, onClick, type = 'button' }) => {
  return (
    <button 
      type={type} 
      className={`btn-reusable ${className} px-4 py-2`} 
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default ReusableButton;
