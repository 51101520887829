import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import $ from "jquery"; 
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";



const data = [];
for (let i = 1; i <= 100; i++) {
  // Add more rows for demo
  data.push({
    key: i,
    name: `John Brown ${i}`,
    age: Number(`${i}2`),
    address: `New York No. ${i} Lake Park`,
  });
}

const EditVendor = () => {
  // Initialize DataTable on mount
  useEffect(() => {
    // Initialize DataTable after the component has mounted
    $("#vendorTable").DataTable({
      paging: true,
      searching: true,
      ordering: true,
      pageLength: 10,
      lengthMenu: [10, 20, 30, 50],
    });

    // Cleanup function to destroy the DataTable when the component unmounts
    return () => {
      $("#vendorTable").DataTable().destroy();
    };
  }, []);

  const navigate = useNavigate();

  const handleAddSubVendor = () => {
    navigate("/vendor-management/add-subvendor");
  };

  return (
    <div>
      <Sidebar />

      <MainSection>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-1 border-r pr-4 ">
            <SectionHeader title="Edit Vendor" />

            <div className="container w-full text-start">
              <div class="my-5">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Vendor Name
                </label>
                <input
                  type="text"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter Vendor name"
                  required
                />
              </div>

              <div class="mb-5">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter email address"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  for="countries"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Vendor Type
                </label>
                <select
                  id="countries"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                >
                  <option selected>Select Compliance or Registration</option>
                  <option value="US">Select 1</option>
                  <option value="CA">Select 2</option>
                  <option value="FR">Select 3</option>
                  <option value="DE">Select 4</option>
                </select>
              </div>

              <div class="mb-5">
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter Phone number"
                  required
                />
              </div>

              <div className="mb-5">
                <label
                  for="countries"
                  class="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Service
                </label>
                <select
                  id="countries"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                >
                  <option selected>Select Service</option>
                  <option value="US">Select 1</option>
                  <option value="CA">Select 2</option>
                  <option value="FR">Select 3</option>
                  <option value="DE">Select 4</option>
                </select>
              </div>

              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#045B9B] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Create Vendor
              </button>
            </div>
          </div>

          <div className="col-span-2">
            <div className="block md:flex md:justify-between justify-center items-center py-4 pr-4">
              <div>
                <SectionHeader title="Sub Accounts" />
              </div>
              <div>
                <button
                  onClick={handleAddSubVendor}
                  className="flex items-center justify-center px-4 py-2 text-white bg-[#045B9B] rounded hover:bg-[#23567a] mr-2 text-sm w-full md:w-auto"
                >
                  <AiOutlinePlus className="mr-2" /> Add Sub Vendor
                </button>
              </div>
            </div>

            {/* Table structure for jQuery DataTable */}

            <DatatableSection>
              <table id="vendorTable" className="display">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.age}</td>
                      <td>{item.address}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </DatatableSection>
          </div>
        </div>
      </MainSection>
    </div>
  );
};

export default EditVendor;
